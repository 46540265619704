









































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: {
        country: [{ required: true, message: '请填写国家代码' }],
        country_name: [{ required: true, message: '请填写国家中文名' }],
        rate: [{ required: true, message: '请填写汇率' }],
      },
      model: {
        country: '',
        country_name: '',
        rate: '',
        status: 0,
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      this.formLoading = true
      const { data: configs } = await this.$http.get(`rates/${this.id}`)
      this.model = Object.assign(this.model, configs)
      this.formLoading = false
    },
    async save() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true
          if (this.id) {
            await this.$http.put(`rates/${this.id}`, this.model)
          } else {
            await this.$http.post('rates', this.model)
          }
          this.loading = false
          this.$router.push('/rate/list')
          this.$message.success('保存成功')
        }
      })
    },
  },
})
